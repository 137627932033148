




















































































































































import { Component, Vue } from 'vue-property-decorator';
import MaterialCard from '@/components/MaterialCard.vue';
import ConfirmActionDialog from '@/components/ConfirmActionDialog.vue';

// Models
import { ScanHardware } from '@/models/ScanHardware.model';
import { Estate } from '@/models/Estate.model';

// --- Services ---
import AppService from '@/services/app';
import AuthService from '@/services/auth';
import ScanHardwareService from '@/services/scanHardware';
import EstateService from '@/services/estate';

// --- Third Party imports ---
import Hashids from 'hashids';

@Component({
  components: {
    MaterialCard,
    ConfirmActionDialog,    
  },
})

export default class Guards extends Vue {
  hashids = new Hashids(process.env.VUE_APP_HASHIDS_SALT);

  // used by Filter Area
  estateFilter: string = '';
  search: string = '';

  // used by No Data Slot
  isLoading: boolean = true;
  confirmActionDelay: boolean = false;

  // used by Item Dialog for Create/Update
  showItemDialog: boolean = false;

  //Most likely won't even need this since it'll just be a listing
  clickedItem: ScanHardware = {
    id: 0,
    estateId: 0,
    estate: '',
    deviceId: '',
    serial: '',
    username: '',
    lastLogin: '',
    make: '',
    purchaseDate: '',
    managed: false,
    alias: '',
    suspendedBy: 0,
    suspended: false,
  };

  // used by Confirm Action Dialog for Delete Item
  action: string = '';
  dialogContent: string = '';
  showConfirmActionDialog: boolean = false;

  title: string = '';
  isUpdate: boolean = false;

  rules = {
  };

  clicked: boolean = false;

  // region Functions to display list data on page load
    headers() {
      return [
        {
          text: 'Estate',
          value: 'estate',
          filter: (value: string) => {
            if (this.estateFilter && this.estateFilter.length >= 1) {
              return (value === this.estateFilter);
            } else {
              return true;
            }
          },
        },
        {
          text: 'Device ID',
          value: 'deviceId',
        },
        {
          text: 'Serial Number',
          value: 'serial',
        },
        {
          text: 'Make',
          value: 'make',
        },
        {
          text: 'Alias',
          value: 'alias',
        },
        {
          text: 'Last Login',
          value: 'lastLogin',
        },
        {
          sortable: false,
          text: '',
          value: 'action',
          align: 'end',
        },
      ];
    }

    async beforeMount() {
      await EstateService.listAll(true);
      await this.listAll();

      const estateId: string = this.hashids.decode(this.$route.params.id) + '';

      if (estateId && estateId.length > 0) {
        const thisEstate = this.$store.getters['estates/estates'].find(
          (estate: Estate) => '' + estate.id === estateId,
        );
        if (thisEstate !== undefined) {
          this.estateFilter = thisEstate.name;
        }
      }
    }

    async listAll() {
      try {
        this.isLoading = true;
        const response: string = await ScanHardwareService.listAll(true);

        if (response.length > 0 && response !== 'No Data Found') {
          AppService.errorHandler(response);
        }

      } finally {
        this.isLoading = false;
      }
    }

    doSuspendActivate() {
      if (this.clickedItem.id <= 0) {
        this.doUpdate();
      }
    }

    async doUpdate() {
      try {
        this.clicked = true;
        this.confirmActionDelay = true;

        const response = await ScanHardwareService.update({
          id: this.clickedItem.id,
          suspended: this.clickedItem.suspended,
        });

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            await this.listAll();
            this.showItemDialog = false;
            this.showConfirmActionDialog = false;
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          await AuthService.logSupportDebug('ScanHardware.vue - doUpdate - 331 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.clicked = false;
        this.confirmActionDelay = false;
      }
    }

    // #region Functions required for the Confirm Action Dialog for Delete
    launchActivateItemDialog(item: ScanHardware) {
      this.clickedItem = item;

      this.action = 'ACTIVATE';
      this.dialogContent = 'You are about to <span style="font-weight: 700;color: #71BC78">re-activate</span> the device <span style="font-weight: 700">' 
        + item.alias + '</span> that belongs to <span style="font-weight: 700">' + item.estate + '</span>.<br/><br/> Are you sure?';

      this.showConfirmActionDialog = true;
    }

    launchSuspendItemDialog(item: ScanHardware) {
      this.clickedItem = item;

      this.action = 'SUSPEND';
      this.dialogContent = 'You are about to <span style="font-weight: 700;color: #71BC78">suspend</span> the device <span style="font-weight: 700">' 
        + item.alias + '</span> that belongs to <span style="font-weight: 700">' + item.estate + '</span>.<br/><br/> Are you sure?';

      this.showConfirmActionDialog = true;
    }

    ConfirmActionDialog_cancel() {
      this.showConfirmActionDialog = false;
    }

    async ConfirmActionDialog_doConfirmed() {
      if (this.action === 'SUSPEND') { 
        this.clickedItem.suspended = true;
        await this.doUpdate();

      } else if (this.action === 'ACTIVATE') {
        this.clickedItem.suspended = false;
        await this.doUpdate();

      }
    }

  // #endregion

  }
