var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('material-card',{staticClass:"px-5 py-3 pt-0",attrs:{"inline":"","icon":_vm.$store.getters['app/icons'].scanner},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Scan Hardware ")]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto padding-under-search",staticStyle:{"max-width":"300px"},attrs:{"append-icon":_vm.$store.getters['app/icons'].search,"label":"Search","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"headers":_vm.headers(),"items":_vm.$store.getters['scanHardwares/scanHardwares'],"sort-by":"name","search":_vm.search,"sort-desc":false,"mobile-breakpoint":"960"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.isLoading)?_c('v-progress-linear',{attrs:{"slot":"progress","indeterminate":_vm.isLoading},slot:"progress"}):_vm._e()]},proxy:true},{key:"item.estate",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.suspended ? 'suspended' : ''},[_vm._v(" "+_vm._s(item.estate)+" ")])]}},{key:"item.deviceId",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.suspended ? 'suspended' : ''},[_vm._v(" "+_vm._s(item.deviceId)+" ")])]}},{key:"item.serial",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.suspended ? 'suspended' : ''},[_vm._v(" "+_vm._s(item.serial)+" ")])]}},{key:"item.make",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.suspended ? 'suspended' : ''},[_vm._v(" "+_vm._s(item.make)+" ")])]}},{key:"item.alias",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.suspended ? 'suspended' : ''},[_vm._v(" "+_vm._s(item.alias)+" ")])]}},{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.suspended ? 'suspended' : ''},[_vm._v(" "+_vm._s(item.lastLogin)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!item.suspended && _vm.$store.getters['users/loggedInUser'].userTypeId === 1)?_c('v-btn',{attrs:{"width":"40px","icon":"","color":"secondary","text":""},on:{"click":function($event){return _vm.launchSuspendItemDialog(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$store.getters['app/icons'].suspend)+" ")])]}}],null,true)},[_c('span',[_vm._v("Suspend")])])],1):_vm._e(),(item.suspended && _vm.$store.getters['users/loggedInUser'].userTypeId === 1)?_c('v-btn',{attrs:{"width":"40px","icon":"","color":"secondary","text":""},on:{"click":function($event){return _vm.launchActivateItemDialog(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$store.getters['app/icons'].activate)+" ")])]}}],null,true)},[_c('span',[_vm._v("Activate")])])],1):_vm._e()]}}],null,true)})],1),[_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.showConfirmActionDialog),callback:function ($$v) {_vm.showConfirmActionDialog=$$v},expression:"showConfirmActionDialog"}},[_c('confirm-action-dialog',{attrs:{"dialogContent":_vm.dialogContent,"confirmActionDelay":_vm.confirmActionDelay},on:{"click:confirmed":_vm.ConfirmActionDialog_doConfirmed,"click:cancel":_vm.ConfirmActionDialog_cancel}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }